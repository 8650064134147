import {inject, LogManager} from "aurelia-framework";
import {Client} from "../../api/client";
import {UserClient} from "../../api/user-client";

const logger = LogManager.getLogger('ChoiceLoader');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(Client, UserClient)
export class ChoiceLoader {

    static Sets = new Map;

    constructor(client, userClient) {
        this.client = client;
        this.userClient = userClient;
    }

    getChoices(config, requestConditions = null, initialValues = null, extraQueryParams = "") {

        if (config.modelId || config.modelPropertyId) {

            let conditions = '';

            if (requestConditions) {
                conditions = 'conditions=' + JSON.stringify(requestConditions);
            }

            if (initialValues) {
                conditions += (conditions.length > 0 ? '&' : '') + 'initialValues=' + JSON.stringify(initialValues);
            }

            if (extraQueryParams) {
                conditions += (conditions.length > 0 ? '&' : '') + extraQueryParams;
            }

            if (config.customLabel) {
                conditions += `&fieldName=${config.customLabel}`;
            }

            const propertyUrl = config.modelPropertyId ? config.modelPropertyId : config.modelId;

            return this.client.get('model/choice/' + propertyUrl + '?' + conditions, 60);

        } else if (config.set) {

            // avoid requesting choice sets again and again
            const fullProperty = config.fullProperty ?? null;
            const isDynamicSet = config.dynamicSet ?? false;

            if (!ChoiceLoader.Sets.has(config.set) || isDynamicSet) {

                const additionalData = {
                    fullProperty: fullProperty,
                    formData: config.fullProperty ? config.formService.getValue() : null
                };

                const queryString = fullProperty ? this.toQueryString(additionalData) : '';
                const uri = 'form/choice/' + config.set + `?${queryString}`;

                const promise = this.client.get(uri)
                    .then(choices => {
                        ChoiceLoader.Sets.set(fullProperty ? config.set : config.set, choices);
                        return Promise.resolve(choices);
                    });

                ChoiceLoader.Sets.set(config.set, promise);

                return promise;
            }

            const choices = ChoiceLoader.Sets.get(config.set);

            return choices instanceof Promise ? choices : Promise.resolve(choices);

        } else if (config.choices) {
            return Promise.resolve(config.choices);
        } else {
            return Promise.resolve([]);
        }
    }

    toQueryString(obj, prefix = '') {
        return Object.entries(obj)
            .flatMap(([key, value]) => {
                const fullKey = prefix ? `${prefix}[${key}]` : key;

                if (value !== null && typeof value === 'object') {
                    return this.toQueryString(value, fullKey);
                }

                return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
            })
            .join('&');
    }

}
